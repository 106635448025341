import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import Image from 'next/image'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { PumpSummary } from '../../types/PumpStations'

Chart.register(annotationPlugin)

const sortPumps = (a: PumpSummary, b: PumpSummary) => {
  return a.deviceId.localeCompare(b.deviceId)
}

const labelPosition = (inx: number): string => {
  return `${20 * (inx + 1) - 2}%`
}

export interface PumpImageProps {
  pumps: PumpSummary[]
  pumpType: string
  stationOnline: boolean
}

const PumpImage = ({ pumps, pumpType, stationOnline }: PumpImageProps) => {
  const graphData = useMemo(() => {
    if (pumps) {
      // Generate Labels
      const labels = pumps.map((pump: PumpSummary) => pump.deviceName)
      // Generate Annotations
      const annotations: any = {}
      pumps.sort(sortPumps).forEach((pump: PumpSummary, i: number) => {
        const keyString: string = '' + pump.deviceId
        annotations[keyString + 'On'] = {
          type: 'line',
          borderColor: '#22c55e',
          borderWidth: 2,
          label: {
            backgroundColor: '#22c55e',
            content: labels[i] + ' On',
            display: true,
            font: {
              size: 10,
            },
            color: 'black',
            position: labelPosition(i),
            z: 1,
          },
          scaleID: 'y',
          value: pump.onLevel ? pump.onLevel : pumps[i - 1].onLevel,
        }
        annotations[keyString + 'Off'] = {
          type: 'line',
          borderColor: '#dc2626',
          borderWidth: 2,
          label: {
            backgroundColor: '#dc2626',
            content: labels[i] + ' Off',
            display: true,
            font: {
              size: 10,
            },
            position: labelPosition(i),
            z: 1,
          },
          scaleID: 'y',
          value: pump.offLevel ? pump.offLevel : pumps[i - 1].offLevel,
        }
      })
      const data = {
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            annotation: {
              annotations: annotations,
            },
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              ticks: {
                font: {
                  size: 12,
                  weight: 'bold',
                },
                padding: 1,
              },
            },
          },
        },
      }

      return data
    }
  }, [pumps])

  // Switch bottom offset to reduce whitespace
  const offsetBottom = () => {
    switch (pumps.length) {
      case 1: {
        return 53 // prev 50
      }
      case 2: {
        return 43
      }
      case 3: {
        return 28
      }
    }
    return 0
  }

  const tankLevelOffset = (defaultOffset: number) => {
    switch (pumps.length) {
      case 1: {
        return 18.5
      }
      case 2: {
        return 22
      }
      case 3: {
        return 27
      }
    }
    return defaultOffset
  }

  const graphOffset = (defaultOffset: number) => {
    switch (pumps.length) {
      case 1: {
        return 17
      }
      case 2: {
        return 20.5
      }
      case 3: {
        return 25.5
      }
    }
    return defaultOffset
  }

  const pump1Paths = ['/pump1Off.png', '/pump1On.png']
  const pump2Paths = ['/pump2Off.png', '/pump2On.png']
  const pump3Paths = ['/pump3Off.png', '/pump3On.png']
  const pump4Paths = ['/pump4Off.png', '/pump4On.png']
  const backgroundPath = '/pumpBackground.png'
  const pumpInletPath = '/pumpInlet.png'

  return (
    <div>
      <div
        className="container"
        style={{
          position: `relative`,
          minHeight: `${575 - offsetBottom()}px`,
          minWidth: `300px`,
          justifyContent: `center`,
          marginBottom: `10px`,
        }}
      >
        <div
          className="background"
          style={{
            position: `absolute`,
            zIndex: 0,
            left: `-webkit-calc(50% - 150px)`,
            bottom: `${offsetBottom()}px`,
          }}
        >
          <Image
            alt="Background"
            height={575}
            width={300}
            src={backgroundPath}
          />
        </div>
        <div
          className="tankLevel"
          style={{
            position: `absolute`,
            height: `365px`,
            width: `262px`,
            top: `${tankLevelOffset(35)}%`,
            left: `-webkit-calc(50% - 133px)`,
            background: `linear-gradient( to top, rgb(20, 131, 234) 0%, rgb(20, 131, 234) ${pumps[0].tankLevel}%, white ${pumps[0].tankLevel}%, white 100%)`,
            zIndex: 0,
          }}
        />
        {
          // PUMP 1
          pumps[0] ? (
            <div
              style={{
                position: `absolute`,
                maxHeight: `575px`,
                maxWidth: `300px`,
                bottom: `${offsetBottom()}px`,
                left: `-webkit-calc(50% - 150px)`,
              }}
            >
              <Image
                alt="Pump Status image"
                height={575}
                width={300}
                src={
                  pumps[0].online && stationOnline && pumps[0].outFrequency! > 0
                    ? pump1Paths[1]
                    : pump1Paths[0]
                }
              />
            </div>
          ) : (
            <></>
          )
        }
        {
          // PUMP 2
          pumps[1] ? (
            <div
              style={{
                position: `absolute`,
                maxHeight: `575px`,
                maxWidth: `300px`,
                bottom: `${offsetBottom()}px`,
                left: `-webkit-calc(50% - 150px)`,
              }}
            >
              <Image
                alt="Pump Status image"
                height={575}
                width={300}
                src={
                  pumps[1].online && stationOnline && pumps[1].outFrequency! > 0
                    ? pump2Paths[1]
                    : pump2Paths[0]
                }
              />
            </div>
          ) : (
            <></>
          )
        }
        {
          // PUMP 3
          pumps[2] ? (
            <div
              style={{
                position: `absolute`,
                maxHeight: `575px`,
                maxWidth: `300px`,
                bottom: `${offsetBottom()}px`,
                left: `-webkit-calc(50% - 150px)`,
              }}
            >
              <Image
                alt="Pump Status image"
                height={575}
                width={300}
                src={
                  pumps[2].online && stationOnline && pumps[2].outFrequency! > 0
                    ? pump3Paths[1]
                    : pump3Paths[0]
                }
              />
            </div>
          ) : (
            <></>
          )
        }
        {
          // PUMP 4
          pumps[3] ? (
            <div
              style={{
                position: `absolute`,
                maxHeight: `575px`,
                maxWidth: `300px`,
                bottom: `${offsetBottom()}px`,
                left: `-webkit-calc(50% - 150px)`,
              }}
            >
              <Image
                alt="Pump Status image"
                height={575}
                width={300}
                src={
                  pumps[3].online && stationOnline && pumps[4].outFrequency! > 0
                    ? pump4Paths[1]
                    : pump4Paths[0]
                }
              />
            </div>
          ) : (
            <></>
          )
        }
        <div
          style={{
            position: `absolute`,
            maxHeight: `575px`,
            maxWidth: `300px`,
            left: `-webkit-calc(50% - 150px)`,
            bottom:
              pumpType === 'Titan' || pumpType === 'X-Series'
                ? `-webkit-calc(5% + ${offsetBottom()}px)`
                : pumpType === 'Vacon'
                ? `-webkit-calc(1.5% + ${offsetBottom()}px)`
                : ``,
          }}
        >
          <Image
            alt="Tank inlet image"
            height={575}
            width={300}
            priority
            src={pumpInletPath}
          ></Image>
        </div>
        <div
          className="graph"
          style={{
            position: `absolute`,
            width: `294px`,
            height: `380px`,
            top: `${graphOffset(24.5 + 9)}%`,
            left: `-webkit-calc(50% - 163px)`,
          }}
        >
          {graphData && graphData.data && graphData.options && (
            <Bar data={graphData.data} options={graphData.options} />
          )}
        </div>
      </div>
    </div>
  )
}

export default PumpImage

import { PumpStationHistoryApiResponse } from '@/types/ApiResponse'
import useAxios from 'axios-hooks'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import TimeframeSelector from './TimeframeSelector'

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

interface StationHistoryProps {
  edgeNodeId: string
}

interface PumpHistoryRequest {
  edgeNodeId: string
  timeframe: string
}

interface PumpHistory {
  tank_level: number
  device_id: string
  reported: Date
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  PointElement,
  LineElement
)

const StationHistory = ({ edgeNodeId }: StationHistoryProps) => {
  const [historyData, setHistoryData] = useState<any>()
  const [historyTimeframe, setHistoryTimeframe] = useState('1d')
  const [
    { data: apiData, loading: historyDataLoading, error: historyDataError },
    refetch,
  ] = useAxios<PumpStationHistoryApiResponse>(
    `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/pump_stations/${edgeNodeId}/history?timeframe=${historyTimeframe}`
  )

  useMemo(() => {
    let active = true
    load()
    return () => {
      active = false
    }
    async function load(): Promise<void> {
      if (!active) {
        return
      }
      if (!apiData || historyDataLoading) {
        return
      }
      const data = []
      const pumpData = apiData.data
        .filter((item) => item.device_id === 'Slave1')
        .map((val) => {
          return { tankLevel: val.tank_level, reported: new Date(val.reported) }
        })
        .sort((a, b) => a.reported.getTime() - b.reported.getTime())
        .map((v) => {
          return {
            tankLevel: v.tankLevel,
            reported: v.reported.toLocaleString([], {
              month: '2-digit',
              day: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            }),
          }
        })
      data.push({
        label: 'lead',
        data: pumpData,
        borderColor: '#0000FF',
      })
      const linegraphConfig = {
        type: 'line',
        data: {
          datasets: data,
        },
        options: {
          parsing: {
            xAxisKey: 'reported',
            yAxisKey: 'tankLevel',
          },
          scales: {
            x: {
              id: 'x',
              display: true,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Tank Level History',
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
        },
      }
      setHistoryData(linegraphConfig)
    }
  }, [apiData, historyDataLoading])

  return (
    <div className="sm:ml-4 mt-2 flex-shrink-0">
      <TimeframeSelector
        timeframe={historyTimeframe}
        setTimeframe={setHistoryTimeframe}
      />
      <div className="flex-1 h-80 sm:m-4 sm:py-1 overflow-hidden sm:p-6">
        {historyData && <Line {...historyData} />}
      </div>
    </div>
  )
}

export default StationHistory

import { AlertConfigWithDetails } from '@/types/Alerts'
import { PumpUser } from '@/types/PumpStations'
import { Combobox, Dialog, Switch, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import useAxios from 'axios-hooks'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useUser } from '../context/userContext'

export interface EditAlertModalProps {
  config: AlertConfigWithDetails | null
  refetchConfigs: () => void
  users: PumpUser[]
  role: string
  open: boolean
  onClose: () => void
  isEmployee: boolean
}

const EditAlertModal = ({
  config,
  refetchConfigs,
  users,
  role,
  open,
  onClose,
  isEmployee,
}: EditAlertModalProps): JSX.Element => {
  const user = useUser()

  const [query, setQuery] = useState('')
  const [querySelection, setQuerySelection] = useState('')

  const filteredUsers: string[] = useMemo(() => {
    const unames = users
      .map((u) => u.name)
      .filter((name) => {
        if (
          role !== 'owner' &&
          !isEmployee &&
          name !== user.user?.displayName
        ) {
          return false
        }
        return !config
          ?.subscriptions!.map((sub) => sub.display_name)
          .includes(name)
      })
    return query === ''
      ? unames
      : unames.filter((u) =>
          u.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        )
  }, [query, users])

  const [enabled, setEnabled] = useState(
    config !== null ? config.enabled : false
  )
  useEffect(() => {
    setEnabled(config !== null ? config.enabled : false)
  }, [config])

  const selectedUser: PumpUser | null = useMemo(() => {
    const foundUser = users.find((u) => u.name === querySelection)
    return foundUser ? foundUser : null
  }, [querySelection])

  const [
    {
      data: updateConfResp,
      loading: updateConfLoading,
      error: updateConfError,
    },
    doUpdateConf,
  ] = useAxios({ method: 'patch' }, { manual: true, autoCancel: false })

  const [
    { data: deleteSubResp, loading: deleteSubLoading, error: deleteSubError },
    doDeleteSub,
  ] = useAxios(
    {
      method: 'delete',
    },
    { manual: true }
  )

  const [
    { data: createSubResp, loading: createSubLoading, error: createSubError },
    doCreateSub,
  ] = useAxios(
    {
      url: `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/alerts/subscriptions`,
      method: 'post',
    },
    { manual: true, autoCancel: false }
  )

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 mt-2"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        Alert Configuration
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="flex flex-col">
                        <Switch.Group
                          as="div"
                          className="flex items-center justify-between py-4"
                        >
                          <div className="flex flex-col">
                            <Switch.Label
                              as="p"
                              className="text-sm font-medium leading-6 text-gray-900"
                              passive
                            >
                              Enable Alert
                            </Switch.Label>
                            <Switch.Description className="text-sm text-gray-500">
                              This will enable or disable all subscriptions to
                              this alert.
                            </Switch.Description>
                          </div>
                          <Switch
                            disabled={
                              !(user.user?.isEmployee || role === 'owner')
                            }
                            checked={enabled}
                            onChange={(isEnabled: boolean) => {
                              setEnabled(isEnabled)
                              doUpdateConf({
                                url:
                                  `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/alerts/configs/` +
                                  config?.id,
                                data: { enabled: isEnabled },
                              })
                                .then((resp) => {
                                  refetchConfigs()
                                })
                                .catch((e) => console.error(e))
                            }}
                            className={`${
                              enabled ? 'bg-green-500' : 'bg-gray-200'
                            } 
                              relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2`}
                          >
                            <span
                              aria-hidden="true"
                              className={`${
                                enabled ? 'translate-x-5' : 'translate-x-0'
                              } 
                                inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                            />
                          </Switch>
                        </Switch.Group>
                        <p className="text-sm font-medium leading-6 text-gray-900">
                          Subscribers
                        </p>

                        <table className="min-w-full divide-y divide-gray-300 shadow border border-gray-200 overflow-hidden ">
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {config &&
                              config.subscriptions &&
                              config.subscriptions.map((sub) => {
                                return (
                                  <tr key={sub.id}>
                                    <td className="whitespace-nowrap flex flex-row w-full py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      <div className="flex flex-col">
                                        <p>{sub.display_name}</p>
                                        <p>{sub.email}</p>
                                      </div>

                                      <button
                                        className="ml-auto"
                                        disabled={
                                          !(
                                            sub.subscriber_id ===
                                              user.user?.uid ||
                                            role === 'owner' ||
                                            isEmployee
                                          )
                                        }
                                        onClick={() => {
                                          doDeleteSub({
                                            url: `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/alerts/subscriptions/${sub.id}`,
                                          })
                                            .then((resp) => {
                                              refetchConfigs()
                                            })
                                            .catch((e) => console.error(e))
                                        }}
                                      >
                                        {(sub.subscriber_id ===
                                          user.user?.uid ||
                                          role === 'owner' ||
                                          isEmployee) && (
                                          <XCircleIcon className="text-red-500 h-6 w-6" />
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                        {role !== 'user' && (
                          <p className="text-sm font-medium leading-6 text-gray-900 mt-3">
                            Add User
                          </p>
                        )}
                        <div className="flex flex-row">
                          {role === 'user' ? (
                            <>
                              <button
                                className="my-1 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300"
                                disabled={
                                  config?.subscriptions?.find(
                                    (sub) =>
                                      sub.subscriber_id === user.user?.uid
                                  ) !== undefined
                                }
                                onClick={() => {
                                  doCreateSub({
                                    data: {
                                      config_id: config?.id,
                                      subscriber_id: user.user?.uid,
                                    },
                                  })
                                    .then((resp) => {
                                      refetchConfigs()
                                    })
                                    .catch((err) => {
                                      console.error(err)
                                    })
                                }}
                              >
                                Subscribe
                              </button>
                            </>
                          ) : (
                            <>
                              <div className="w-full my-1 mr-1">
                                <Combobox
                                  value={querySelection}
                                  onChange={setQuerySelection}
                                >
                                  <div className="relative mt-1">
                                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md border border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                      <Combobox.Input
                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                        onChange={(e) =>
                                          setQuery(e.target.value)
                                        }
                                      />
                                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronDownIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      </Combobox.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {filteredUsers.map((u) => (
                                          <Combobox.Option
                                            key={u}
                                            value={u}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 pr-4 pl-2 ${
                                                active
                                                  ? 'bg-blue-500 text-white'
                                                  : 'text-gray-900'
                                              }`
                                            }
                                          >
                                            {u}
                                          </Combobox.Option>
                                        ))}
                                      </Combobox.Options>
                                    </Transition>
                                  </div>
                                </Combobox>
                              </div>
                              <button
                                onClick={() => {
                                  // do add user
                                  doCreateSub({
                                    data: {
                                      config_id: config?.id,
                                      subscriber_id: selectedUser?.uid,
                                    },
                                  })
                                    .then((resp) => {
                                      refetchConfigs()
                                    })
                                    .catch((err) => {
                                      console.error(err)
                                    })
                                  setQuerySelection('')
                                  setQuery('')
                                }}
                                disabled={
                                  !(
                                    isEmployee ||
                                    role === 'owner' ||
                                    selectedUser?.uid === user.user?.uid
                                  )
                                }
                                // className="bg-green-500 text-white outline outline-1 text-b text-sm flex self-center py-2 mr-4 px-2 rounded shadow mt-2"
                              >
                                <PlusCircleIcon className="text-green-500 w-6 h-6 flex items-center my-1 " />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default EditAlertModal

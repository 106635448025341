import { AlertStatusResponse } from '@/types/Alerts'
import { ReactElement } from 'react'

interface ActiveAlertsProps {
  alerts: AlertStatusResponse[]
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

const AlertsHistory = ({ alerts }: ActiveAlertsProps): ReactElement | null => {
  if (!alerts || alerts.length === 0) return null
  return (
    <div className="min-w-full mt-4">
      <h2 className="text-md font-medium leading-6 text-gray-900">History</h2>
      <div className="hidden sm:block overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mt-2">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Alerted At
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Acknowledged At
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Acknowledged By
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {alerts.map((alert) => (
              <tr key={alert.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {alert.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {new Date(alert.alerted_at).toLocaleString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {new Date(alert.acknowledged_at).toLocaleString()}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {alert.acknowledged_by.display_name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="md:hidden">
        {alerts.map((alert) => (
          <div
            key={alert.id}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-xl mb-4"
          >
            <div key={alert.id} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                {alert.name}
              </dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <p className="text-sm text-gray-500">Alerted at</p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    {new Date(alert.alerted_at).toLocaleString()}
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Acknowledged by</p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    {alert.acknowledged_by.display_name}
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Acknowledged at</p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    {new Date(alert.acknowledged_at).toLocaleString()}
                  </div>
                </div>
              </dd>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AlertsHistory

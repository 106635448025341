import { AlertStatusResponse } from '@/types/Alerts'
import { AlertsConfigApiResponse } from '@/types/ApiResponse'
import { DeviceRequest } from '@/types/Devices'
import {
  DatabaseSummary,
  PumpSummary,
  PumpUser,
  StationSummary,
} from '@/types/PumpStations'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import useAxios from 'axios-hooks'
import { httpsCallable } from 'firebase/functions'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import ActiveAlerts from '../components/ActiveAlerts'
import AlertsConfig from '../components/AlertsConfig'
import ModeChangeModal from '../components/ModeChangeModal'
import OverlayLoading from '../components/OverlayLoading'
import PumpImage from '../components/pumpImage'
import Section from '../components/Section'
import StationHistory from '../components/StationHistory'
import { useUser } from '../context/userContext'
import { fbFunctions } from '../lib/firebase'
import { useInterval } from '../lib/hooks'

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

interface PumpLineColors {
  [key: string]: string
}

const pumpLineColors: PumpLineColors = {
  Slave1: '#0000FF',
  Slave2: '#00FF00',
  Slave3: '#7CFC00',
  Slave4: '#FFA500',
}

const setPumpMode = async (edgeNodeId: string, mode: string): Promise<void> => {
  const pumpModeMsg: DeviceRequest = {
    edgeNodeId: edgeNodeId,
    command: 'mode',
    data: mode,
  }
  const setPumpFn = httpsCallable(fbFunctions, 'deviceRequest')
  await setPumpFn(pumpModeMsg)
}

const sortPumps = (a: PumpSummary, b: PumpSummary) => {
  return a.deviceId.localeCompare(b.deviceId)
}

interface UserAccessDoc {
  resourceId: string
  role: string
  type: string
  userId: string
}

const RangerDetailsPage = (): JSX.Element => {
  const { user } = useUser()
  const [readOnly, setReadOnly] = useState(true)
  const router = useRouter()
  const edgeNodeId = router.query.id as string
  const [disableSetModeButton, setDisableSetModeButton] =
    useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [changeModeTo, setChangeModeTo] = useState<string>('')
  const [userRole, setUserRole] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<PumpUser[]>([])

  const [
    { data: stationData, loading: stationLoading, error: stationDataError },
    refetch,
  ] = useAxios(
    `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/pump_stations/${edgeNodeId}`,
    {}
  )

  const [
    {
      data: alertsConfig,
      loading: alertsConfigLoading,
      error: alertsConfigError,
    },
    refetchAlertsConfig,
  ] = useAxios<AlertsConfigApiResponse>(
    `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/alerts?edge_node_id=${edgeNodeId}`
  )

  const alertsActive: AlertStatusResponse[] = useMemo(() => {
    const statuses: AlertStatusResponse[] = []
    if (alertsConfig) {
      alertsConfig.data.forEach((alert) => {
        if (alert.statuses) {
          statuses.push(...alert.statuses.filter((s) => !s.acknowledged))
        }
      })
    }
    return statuses
  }, [alertsConfig])

  const station: StationSummary | undefined = useMemo(() => {
    try {
      if (stationData) {
        const dbSummary: DatabaseSummary = {
          message: stationData.message,
          data: [stationData.data].map((stationResult: any) => {
            const pumpData: PumpSummary[] = stationResult.pumps.map(
              (pumpResult: any) => {
                const pump: PumpSummary = {
                  deviceId: pumpResult.device_id,
                  deviceName: pumpResult.name,
                  mode: pumpResult.mode,
                  online: pumpResult.online,
                  isFaulted: pumpResult.is_faulted,
                  outCurrent: pumpResult.out_current,
                  outFrequency: pumpResult.out_frequency,
                  tankLevel: pumpResult.tank_level,
                  switchPosition: pumpResult.switch_position,
                  onLevel: pumpResult.on_level,
                  offLevel: pumpResult.off_level,
                  type: pumpResult.type,
                  reported: pumpResult.reported,
                }
                return pump
              }
            )
            const newUserMap: { [key: string]: PumpUser } = {}
            const formattedUsers: PumpUser[] = stationResult.users.map(
              (userResult: any) => {
                const newUser: PumpUser = {
                  uid: userResult.uid,
                  name: userResult.name ? userResult.name : 'Unknown',
                  role: userResult.role,
                  email: userResult.email,
                  phoneNumber: '',
                }
                newUserMap[newUser.uid] = newUser
                return newUser
              }
            )
            setUsers(formattedUsers)
            const currentUser = formattedUsers.find((u: PumpUser) => {
              return u.uid === user!.uid
            })
            if (currentUser && currentUser.role) {
              setUserRole(currentUser.role)
            }

            const station: StationSummary = {
              edgeNodeId: stationResult.edge_node_id,
              edgeNodeName: stationResult.name ? stationResult.name : '',
              online: stationResult.online,
              isGeneratorPowered: stationResult.is_generator_powered,
              isIrrigation: stationResult.is_irrigation,
              dischargeTankLevel: stationResult.discharge_tank_level,
              lastReported: stationResult.reported,
              latitude: stationResult.location
                ? stationResult.location.latitude
                : undefined,
              longitude: stationResult.location
                ? stationResult.location.longitude
                : undefined,
              pumps: pumpData,
              reportingInterval: stationResult.reporting_interval,
              relayStatus: stationResult.relay_status,
              signalStrength: stationResult.signal_strength,
              temperature: stationResult.temperature,
            }
            return station
          }),
        }
        return dbSummary.data[0]
      }
      return
    } catch (error) {
      return
    } finally {
      setLoading(false)
    }
  }, [stationData, user])
  useInterval(refetch, 10000)

  const stationInAuto: boolean =
    station !== undefined &&
    station.pumps &&
    station.pumps.every((p) => p.mode === 'Auto')
  const vaconSwitchWrongPosition: boolean =
    station !== undefined &&
    station.pumps &&
    station.pumps.some((p) => p.switchPosition === false)

  const getVaconWrongSwitchPositionList = () => {
    if (!station?.pumps) return
    const vaconVFDsSwitchWrongPosition =
      station.pumps && station.pumps.filter((p) => p.switchPosition === false)
    const vfdList: string[] = []
    vaconVFDsSwitchWrongPosition?.forEach(
      (vfd) => vfd.deviceName && vfdList.push(vfd.deviceName)
    )
    return vfdList.join(',')
  }

  const xSeriesDisableModeChange = (pumpId: string) => {
    if (!station?.pumps) return true
    const pumpsWithWrongSwitchPosition = Object.entries(station.pumps).filter(
      (p) => {
        const hoaHand = p[1].metrics!.find((m) =>
          m.name?.toLowerCase().includes('hoa hand')
        )
        const revCon = p[1].metrics!.find((m) =>
          m.name?.toLowerCase().includes('rev con')
        )
        if (hoaHand && hoaHand.value === '1' && revCon && revCon.value === '0')
          return true
        return false
      }
    )
    if (pumpsWithWrongSwitchPosition.length >= 1) return true
    return false
  }

  useEffect(() => {
    if (stationLoading) {
      setReadOnly(true)
    } else if (
      (station && userRole && userRole !== 'readOnly') ||
      (user && user.isEmployee)
    ) {
      setReadOnly(false)
    } else {
      setReadOnly(true)
    }
  }, [station, stationLoading, user, userRole])

  useEffect(() => {
    if (!stationLoading) {
      setLoading(false)
    }
  }, [stationLoading])

  if (loading) return <OverlayLoading />

  if (stationData === undefined || station === undefined) {
    return <div>No device found</div>
  }

  const onModeChangeClicked = async (newMode: string): Promise<void> => {
    setDisableSetModeButton(true)
    setTimeout(() => setDisableSetModeButton(false), 45000)
    await setPumpMode(edgeNodeId, newMode)
  }

  return (
    <div className="w-full">
      <ModeChangeModal
        open={modalOpen}
        setOpen={setModalOpen}
        changeModeTo={changeModeTo}
        onModeChangeClicked={onModeChangeClicked}
      />
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                <div>
                  <div className="flex items-center">
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                      {station.edgeNodeName}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Company</dt>
                    <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                      <ArrowPathIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      {`Last Updated: ${
                        station.lastReported
                          ? new Date(station.lastReported).toLocaleString()
                          : 'N/A'
                      }`}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              {station.isGeneratorPowered === true && (
                <span
                  className={`inline-flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                    station
                      .pumps!.sort(sortPumps)
                      .some((pump) => pump.online === false)
                      ? 'bg-orange-500 text-white'
                      : 'bg-green-500 text-black'
                  }`}
                >
                  {`${
                    station
                      .pumps!.sort(sortPumps)
                      .some((pump) => pump.online === false)
                      ? 'Generator Stopped'
                      : 'Generator Running'
                  }`}
                </span>
              )}
              {station.pumps.some((pump) => pump.isFaulted) && (
                <span className="inline-flex items-center px-2.5 py-0.5 mr-2 rounded-md text-sm font-medium bg-red-600 text-white">
                  Fault
                </span>
              )}
              <span
                className={`inline-flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                  station.online
                    ? 'bg-green-500 text-black'
                    : 'bg-orange-500 text-white'
                }`}
              >
                {`${station.online ? 'Online' : 'Offline'}`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ActiveAlerts alerts={alertsActive} refetch={refetchAlertsConfig} />

      <Section title="Overview">
        <div className="flex flex-col columns-2 lg:flex-row">
          <div className="w-full col-span-1">
            <PumpImage
              pumps={station.pumps}
              pumpType={
                station.pumps[0].type !== undefined ? station.pumps[0].type : ''
              }
              stationOnline={station.online}
            />
          </div>
          <div className="w-full col-span-1">
            <div className="px-4 py-5 sm:p-0">
              <h2 className="text-md font-medium leading-6 text-gray-900">
                Mode
              </h2>
              <dl className="">
                <dd className="flex justify-center pt-4 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                  <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                      type="button"
                      disabled={
                        vaconSwitchWrongPosition ||
                        disableSetModeButton ||
                        stationInAuto ||
                        readOnly
                      }
                      onClick={() => {
                        setChangeModeTo('Auto')
                        setModalOpen(!modalOpen)
                      }}
                      className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 ${
                        stationInAuto ? 'bg-lime-600' : 'bg-white'
                      } ${
                        disableSetModeButton || stationInAuto || readOnly
                          ? ''
                          : 'hover:bg-slate-50'
                      } disabled:cursor-not-allowed disabled:opacity-75 text-sm font-medium text-gray-700  focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                    >
                      Auto
                    </button>
                    <button
                      type="button"
                      disabled={
                        vaconSwitchWrongPosition ||
                        disableSetModeButton ||
                        !stationInAuto ||
                        readOnly
                      }
                      onClick={() => {
                        setChangeModeTo('Manual')
                        setModalOpen(!modalOpen)
                      }}
                      className={`-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 ${
                        !stationInAuto
                          ? 'bg-red-600 text-white'
                          : 'bg-white text-gray-700'
                      }  ${
                        disableSetModeButton || !stationInAuto || readOnly
                          ? ''
                          : 'hover:bg-slate-50'
                      }
                      disabled:cursor-not-allowed disabled:opacity-75 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                    >
                      Manual (Winter)
                    </button>
                  </span>
                </dd>
                <dd
                  className={`${
                    (!vaconSwitchWrongPosition || !xSeriesDisableModeChange) &&
                    'hidden'
                  } flex flex-col justify-center pt-4 text-sm text-gray-900 sm:mt-0`}
                >
                  <div className="border border-red-500 rounded-lg m-4 p-4 flex flex-col text-center">
                    <span className="text-center text-lg">
                      All VFD physical switches must be in Remote in order to
                      change mode remotely.
                    </span>
                    <span className="text-center text-lg">
                      Please flip physical switch on the following VFDs:
                      {' ' + getVaconWrongSwitchPositionList()}
                    </span>
                  </div>
                </dd>
                <dd
                  className={`${
                    !disableSetModeButton && 'hidden'
                  } flex flex-col justify-center pt-4 text-sm text-gray-900 sm:mt-0`}
                >
                  <span className="text-center">Pending mode change...</span>
                  <span className="text-center text-xs text-slate-400">
                    Please wait up to 1 minute before changing again
                  </span>
                </dd>
              </dl>
            </div>
            <div className="mt-8 flow-root">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                        {station.pumps?.map((pump) => (
                          <th
                            key={pump.deviceId}
                            scope="col"
                            className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {pump.deviceName && pump.deviceName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Mode
                        </td>
                        {station.pumps.map((pump) => (
                          <td
                            key={pump!.deviceId}
                            className="px-6 py-4 whitespace-nowrap text-sm text-white"
                          >
                            <span
                              className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
                                pump.mode === 'Auto'
                                  ? 'bg-green-500 text-black'
                                  : 'bg-black text-white'
                              }`}
                            >
                              {pump.online && station.online
                                ? pump.mode
                                : 'Unknown'}
                            </span>
                          </td>
                        ))}
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Tank Level
                        </td>
                        {station.pumps!.map((pump) => (
                          <td
                            key={pump!.deviceId}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                          >
                            {pump.tankLevel && pump.online && station.online
                              ? pump.tankLevel?.toFixed(0) + '%'
                              : 'N/A'}
                          </td>
                        ))}
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          On Level
                        </td>
                        {station.pumps!.map((pump) => (
                          <td
                            key={pump!.deviceId}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                          >
                            {pump.online && station.online
                              ? pump.onLevel
                              : 'N/A'}
                          </td>
                        ))}
                      </tr>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Off Level
                        </td>
                        {station.pumps?.map((pump) => (
                          <td
                            key={pump.deviceId}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                          >
                            {pump.online && station.online && pump.offLevel
                              ? pump.offLevel
                              : 'N/A'}
                          </td>
                        ))}
                      </tr>
                      <tr
                        className={
                          station.pumps!.filter((pump) => {
                            return pump.outFrequency && pump.outFrequency > 0
                          }).length > 0
                            ? 'bg-blue-300'
                            : 'bg-white'
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Pump Frequency
                        </td>
                        {station.pumps?.map((pump) => (
                          <td
                            key={pump.deviceId}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                          >
                            {pump.online &&
                            station.online &&
                            pump.outFrequency !== null &&
                            pump.outFrequency !== undefined
                              ? pump.outFrequency + ' Hz'
                              : 'N/A'}
                          </td>
                        ))}
                      </tr>
                      <tr
                        className={
                          station.pumps!.filter((pump) => {
                            return pump.outFrequency && pump.outFrequency > 0
                          }).length > 0
                            ? 'bg-blue-300'
                            : 'bg-gray-50'
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Pump Current
                        </td>
                        {station.pumps?.map((pump) => (
                          <td
                            key={pump.deviceId}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                          >
                            {pump.online &&
                            station.online &&
                            pump.outCurrent !== undefined &&
                            pump.outCurrent !== null
                              ? pump.outCurrent + ' Amps'
                              : 'N/A'}
                          </td>
                        ))}
                      </tr>
                      {/* <tr className="bg-white">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Fault
                        </td>
                        {station.pumps!.map((pump) => (
                          <td
                            key={pump!.deviceId}
                            className={`px-6 py-4 whitespace-nowrap text-sm ${
                              pump.isFaulted ? 'text-red-500' : 'text-green-600'
                            }`}
                          >
                            {pump.isFaulted ? 'Fault' : 'No Fault'}
                          </td>
                        ))}
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section title="Alerts">
        <AlertsConfig
          configs={alertsConfig?.data}
          refetchConfigs={refetchAlertsConfig}
          users={users}
          role={userRole ? userRole : ''}
          isEmployee={
            user && user.isEmployee !== null ? user.isEmployee : false
          }
        />
      </Section>

      <Section title="Station History">
        <StationHistory edgeNodeId={edgeNodeId} />
      </Section>
    </div>
  )
}

export default RangerDetailsPage
